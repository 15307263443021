<template>
  <div>
    <div class="app-container" style="width: 40%; ">
      <el-form ref="form" :model="form" label-width="80px" :rules="rules">
        <el-form-item style="text-align: center" label-width="0">
        </el-form-item>
        <el-form-item label="昵称">
          <el-input v-model="form.nickName" disabled ></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="mobile">
          <el-input v-model="form.mobile"></el-input>
        </el-form-item>
      </el-form>
      <div style="text-align: center">
        <el-button type="primary" @click="update">保存</el-button>
      </div>
    </div>
  </div>

</template>

<script>
import {updateCompany } from "@/api/company";

export default {
  name: "Person",
  data() {
    return {
      form: {},
      rules: {
        mobile: [
          {required: true, message: '请输入', trigger: 'blur'},
        ],
        nickName: [
          {required: true, message: '请输入', trigger: 'blur'},
        ],
      },
    }
  },
  created() {
    let str = sessionStorage.getItem("user") || "{}"
    this.form = JSON.parse(str);
  },
  methods: {
    update() {
      this.form.username = this.form.mobile;
      updateCompany(this.form).then(res => {
        if (res.code === '0') {
          this.$message({
            message: '更新成功',
            type: 'success',
          });
        }else {
          this.$message({
            type: "error",
            message: res.msg
          })
          return
        }
      });
    }
  }
}
</script>

<style>

</style>
